.analytics-btn {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 120px;
    height: 30px;
    border: 2px solid #FFFFFF;
}

.analytics-btn:hover{
    color: #FFFFFF !important;
    border-color: var(--btnHover)!important;
    background: var(--btnHover) !important;
}

.chart-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.exp-dropDown {
 margin-right: 15px !important;
 margin-left: 15px !important;
 width: 200px;
}

.select__control {
   min-height: 30px;
   cursor: pointer !important;
}

.exp-dropdown-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.exp-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btn-All {
    color: #415464;
    background-color: hwb(210 92% 5%);
    border: 1px solid #EBEFF3;
}

.analytics-name {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #3B3B3B;
}

.btn-active {
    color: #FFFFFF;
    background: var(--dark);
    border: 1px solid var(--dark);
}

.bar-chart {
    margin-top: 20px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 20px;
    position: relative;
}

.filter {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px; 
    color: #415464;
    margin-right: 10px;
}

.chart-content {
    margin-top: 46px;
}

.chart-select {
    margin: 0 10px;
    border: 2px solid rgba(143, 146, 161, 0.2);
    border-radius: 4px;
    height: 30px;
    padding-left: 10px;
    padding-right: 26px;
    flex: none;
    order: 3;
    align-self: center;
    flex-grow: 0;
    appearance: none;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 15px;
}

/* .apexcharts-canvas {
    height: 300px !important;
} */

.pieChart {
    margin-top: 16px;
    min-height: 200px !important;
    min-width: 200px !important;
    width: 400px;
}
.filter-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.chart-option { 
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1E1F20;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.activity-chart {
    padding: 15px 20px;
    background: #FFFFFF;
    border-radius: 12px;  
}

.filter-charts {
    margin: 35px 0;
    display: flex;
    justify-content: space-between;
}

.learner-charts {
    margin: 40px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activity-text {   
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #7F85A2;
}

.activity-subText {   
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7F85A2;
}
.bar-box {
    width: 30%;
}

.vertical-bar {
    height: 161px;
    width: 26px;
    background: #EBEFF3;
    border-radius: 10px;
    border: 1px solid #EBEFF3;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
}

.finish-bar-1 {
    display: flex;
    justify-content: center;
    /* align-items: flex-start; */
    margin: 20px;
    position: relative;
}

.finish-bar-2 {
    display: flex;
    justify-content: center;
    /* align-items: end; */
    margin: 20px;
    position: relative;
}

.finish-bar-3 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin: 20px;
    position: relative;
}

.per-content {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px;
}

.pgb-value {
    background: #FFCCBE;
    position: sticky;
    border-radius: 0px 0px 10px 10px;
    width: inherit;
}

.pgb-border {
    border-radius: 10px 10px 10px 10px;
}

.per-label {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #3B3B3B;
}

.per-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #3B3B3B;
}

.clock {
    margin-right: 25px;
    width: 110px;
    height: 130px;
    background-image: url("../../images/Clock.svg");
    background-repeat: no-repeat;
}
.clock-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.sec-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #3B3B3B;
}

.sec-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.apexcharts-legend-text {
    font-family: 'Mulish' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #415464 !important;
    margin-top: 20px !important;
}

.learner-text {
    font-weight: 700;
    font-size: 16px;
    color: #3B3B3B;
}

.point-circle {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 0 10px;
}

.exp-sutText {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.44px;
    text-transform: uppercase;
    color: #3B3B3B;
    flex: none;
    order: 1;
    flex-grow: 0;   
    text-transform: uppercase;
}

.learner-exp-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.exp-chart-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
}

.exp-sutTitle {
    margin: 0 20px;
    display: flex;
    align-items: center;
}

.active-learner-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #415464;
    margin: 0 8px;
}

.active-learner-number {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #415464;
}

.learner-div {
    margin: 16px 0;
    display: flex;
}

.pie-chart-contents {
    display: flex;
    align-items: center;
    margin: 15px;
    justify-content: center;
    /* width: 80%; */
}

.learner-pie-legend {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.pie-legand {
    margin-top: 15px;
}

.timeChart{
    width: 40%;
}

.circleChart {
    width: 55%;
}

.button-radius-0 {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.button-radius-2{
border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.bar-chart-loader{
    background-image: url('../../images/loadingDots.gif');
    position: absolute;
    background-repeat: no-repeat;
    height: 20px;
    top: 50%;
    left: 50%;
    width: 100px;
}

.ar-disabled{
    cursor:not-allowed !important;
    color: #415464 !important;
   background-color: hwb(210 92% 5%) !important;
    border: 1px solid #EBEFF3 !important;
}

.ar-disabled:hover{
    cursor:not-allowed !important;
    color: #415464 !important;
   background-color: hwb(210 92% 5%) !important;
    border: 1px solid #EBEFF3 !important;
}