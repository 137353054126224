.select-device-container {
    background: rgba(255, 255, 255, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.select-device-header {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 145%;
    text-align: center;
    color: #1E1F20;
}


.select-device-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 100px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.button-color {
    color: #1E1F20;
}

.button-color:hover {
    text-decoration: underline;
    color: #1E1F20 !important;
}

.target-url {
    color: #ffff;
    align-items: center;
    /* padding: 20px; */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.target-url a {
    color: #1E1F20;
    ;
    text-decoration: none;
}

.target-url a:hover {
    color: #FFFFFF;
    text-decoration: underline;
    cursor: pointer;
}

.create-code-container {
    background: #FFFFFF;
    height: 100%;
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.create-code-header {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 145%;
    /* identical to box height, or 32px */
    text-align: center;
    color: #1E1F20;
    margin-bottom: 20px;
}

.crete-code-sub-header {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    text-align: center;
    color: #1E1F20;
    ;
    margin-bottom: 20px;
}

.code-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.digit-box {
    color: #1E1F20;
    padding: 20px;
    /* border: 1px solid #4B4A4E; */
    background: #E2E9F0;
    border-radius: 2px;
}

.headset-btn {
    background: #0056D2;
    padding: 18px 35px !important;
    opacity: 1 !important;
}

.headset-btn:hover {
    background: #99C3FF;
    border-color: #99C3FF;
}

.select-device-btn-box {
    background: linear-gradient(165.4deg, #0056D2 -10.07%, rgba(72, 135, 226, 0.5) 89.61%);
    position: relative;
    height: 350px;
    width: 290px;
    border-radius: 5px;
}

.select-device-box-inner {
    background: rgba(255, 255, 255, 1);
    margin: 2px;
    width: 286px;
    height: 346px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.select-device-btn {
    position: absolute;
    bottom: -28px;
    left: 13%;
}

.select-ar-btn {
    position: absolute;
    bottom: -28px;
    left: 20%;
}

.blue-color {
    color: #0056D2;
    font-weight: 700;
}

.crate-code-background {
    border: 1px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-radius: 4px;
    min-height: 165px;
    width: 650px;
    border-image-source: linear-gradient(180deg, #0056D2 0%, #4887E2 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.loading {}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
}

.loading-btn-processing {
    display: flex;
    width: 87px !important
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.container-ar {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
}

.select-ar {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    align-items: center;
    gap: 25px;
    /* margin-right: 50px; */
}

.scanner-thumb {
    width: 150px;
}

.box-ar {
    background: linear-gradient(165.4deg, #0056D2 -10.07%, rgba(72, 135, 226, 0.5) 89.61%);
    position: relative;
    display: flex;
    justify-content: center;
    max-height: 406px;
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    border-radius: 5px;
    margin: 20px;
}

.box-ar-inner {
    background: rgba(255, 255, 255, 1);
    margin: 2px;
    width: 100vw;
    height: 100vh;
    max-width: 396px;
    max-height: 402px;
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}
