.profile-tab-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #979797;
    cursor: pointer;
}

.profile-tab-name:hover{
    color: #415464;
}

.profile-selected-tab{
    color: #415464 !important;
}

.profile-underline {
    border: 2px solid #415464;
    border-bottom: none;
    width: 50px;
}

.edit-profile-tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    /* position: absolute; */
    top: 10vh;
}

.profile-underline-div {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.justify-space-evenly{
    justify-content: space-evenly;
}