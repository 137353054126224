.login-page {
    padding: 62px 72px;
    height: 100vh;
}

.login-wrapper {
    background: #FBFBFB;
    border-radius: 4px;
    box-shadow: 0px 10px 29px rgba(115, 115, 115, 0.17);
    display: flex;
    align-items: center;
    display: flex;
    height: 100%;
    overflow: auto;
    max-width: 1315px;
    min-height: 600px;
    margin: 0 auto;
}

.login-wrapper .left-side {
    background-image: url(../../images/login-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

.remeber-password-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    margin-top: 40px;
    color: #6D6D6D;
    font-Weight: 500;
    font-size: 18px;
    line-height: 22.59px
}

.forgot-password {
    text-align: center;
    color: #6D6D6D;
    text-decoration: none;
    cursor: pointer;
}

.forgot-password:hover{
    color: var(--btnHover);
}

.form-check-input {
    margin-top: 0.1rem !important;
}

.checkbox-wrapper>* {
    cursor: pointer;
}

.checkbox-wrapper .form-check-input:checked {
    border-color: var(--dark);
    background-color: #ffffff;
}

.checkbox-wrapper .form-check-input:hover {
    border-color: #F1F1F1;
    background-color:#F1F1F1;
    color: var(--dark);
}

.checkbox-wrapper .form-check-input:checked[type=checkbox] {
    background-image: url('../../images/check.svg');
    background-size: auto;
}

.checkbox-wrapper label:hover{
    color: var(--btnHover);
}
.c-wrapp {
    width: 100%;
    max-width: 500px;
}

.left-side,
.right-side {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    position: relative;
}

.left-side {
    background: #F5F5F5;
}

.dont-have-account {
    /* position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%); */
    text-align: center;
    color: #6D6D6D;
    font-size: 14px;
    line-height: 17.57px;
    font-weight: 500;
}

.login-text {
    font-size: 46px;
    line-height: 57.73px;
    font-weight: 700;
    color: #3B3B3B;
    margin-bottom: 38px;
}

.welcome {
    font-size: 26px;
    line-height: 32.63px;
    font-weight: 400;
    text-transform: uppercase;
    color:#415464
}

.brand-name {
    font-size: 46px;
    line-height: 57.73px;
    font-weight: 900;
    color: #415464;
}

.login-desc {
    font-size: 20px;
    line-height: 25.1px;
    font-weight: 400;
    color: #6D6D6D;
}

.user-email {
    font-size: 18px;
    line-height: 22.59px;
    font-weight: 500;
    color: #6D6D6D;
    margin-bottom: 40px;
}

.terms-conditions {
    color: #6D6D6D;
    font-Weight: 500;
    font-size: 18px;
    line-height: 22.59px;
    margin-bottom: 20px;
    margin-top: 65px;
}

.terms-conditions a {
    text-decoration: underline;
}

.reset-page {
    height: 100%;
    display: flex;
    justify-content: center;
}

.error-mail {
    color: #BE2222;
}

.success-mail {
    color: green;
}

.term-body{
    height: 65vh;
    overflow: auto;
}

.term-cond-container{
    width: 60%;
}