.app {
  background: #FBFBFB;
  /* display: flex; */
  text-align: center;
  height: 100vh;
  overflow: auto;

}

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40.16px;
  margin-bottom: 24px;
  color: #3B3B3B;  
}

.subTitle {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px; 
}

.hr-line {
  margin: 30px 0 20px 0 ;
}

select:hover{
  cursor: pointer;
}

option{
  cursor: pointer;
}
