.file-container {
    border-width: 2px;
    border-style: dashed;
    border-color: var(--dark);
    border-radius: 10px;
    height: 120px;
    width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
}
.file-container:hover{
    background: rgba(65, 84, 100, 0.1);
    border-color: var(--btnHover);
}

.upload-icon{
 color: #1E1F20; 
}

.cloud-upload {
    width: 34px;
    height: 24px;
    color: var(--secondary);
}

.file-size-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */
    color: #A8A8A8;
}

.upload-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.progressbar-subcontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 4px;
}

.file-name {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.zip-container {
    display: flex;
    align-items: center;
    margin: 20px;
}

.zip-upload-image {
    background-image: url("../../images/zip-icon.svg");
}

.zip-upload {
    width: 34px;
    height: 30px;
    background-repeat: no-repeat;
    display: -webkit-inline-box;
    margin-right: 20px;
}