.profileAppearenceContainer {
    margin-top: 25px;
    margin-bottom: 45px;
}

.profileAppearenceHeader {
    font-weight: 500;
    font-size: 18px;
    line-height: 145%;
    color: #3B3B3B;
}


.selectAccentBlock {
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #3B3B3B;
    margin-bottom: 40px;
}

.headerRow {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 145%;
    color: #3B3B3B;
    margin-bottom: 20px;
}

.colorTypeBlock {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.color-filled-square {
    border: 2px solid #ADADAD;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
}

.primaryBlock {
    display: flex;
    margin-bottom: 20px;
    color: #3B3B3B;
}

.secondaryBlock {
    display: flex;
    color: #3B3B3B;
}

.primaryBlock>div:nth-child(1) {
    margin-right: 41px;
}

.secondaryBlock>div:nth-child(1) {
    margin-right: 20px;
}

.color-filled-square>div:nth-child(1) {
    width: 16px;
    height: 16px;
    background-color: #415464;
    border-radius: 4px;
}

.color-filled-square>div:nth-child(2) {
    background-image: url('../../images/RightArrow.svg');
    width: 6px;
    height: 10px;
    background-size: contain;
    margin-left: 8px;
}

.colorPickerBlock {
    background: rgba(204, 204, 204, 0.3);
    backdrop-filter: blur(2px);
    border-radius: 4px;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
}



.color-filled {
    border: 2px solid rgba(204, 204, 204, 0);
    border-radius: 4px;
    margin: 0 3px;
}

.color-filled>div {
    width: 16px;
    height: 16px;
    margin: 3px;
    border-radius: 4px;
    cursor: pointer;
}

.firstRow,
.secondRow {
    display: flex;
}

.thirdRow {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #9DA7B0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.thirdRow div:nth-child(1) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #415464;
}

.thirdRow div:nth-child(2) {
    width: 16px;
    height: 16px;
    background: #607180;
    border-radius: 4px;
    margin: 0 5px;
}

.thirdRow div:nth-child(3) {
    background: #D8D8D8;
    border-radius: 2px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
    text-align: right;
    color: #415464;
    padding: 5px;
    min-width: 50px;
    text-align: center;
}

.thirdRow div:nth-child(3) input {
    background: transparent;
    border: none;
    width: 80px;
}

.thirdRow div:nth-child(3) input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.profile-org-details .profileAppearenceHeader{
 font-size: 14px;
 font-weight: 700;
}