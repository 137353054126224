
.experience-container {       
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edit-description {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #3B3B3B;
}
.edit-exp-name {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #343338;
}
.steps-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 20px;
}

.step {
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider{
    background-image: url("../../images/RightArrow.svg");    
    background-repeat: no-repeat;
    width: 14px;   
    background-position: center;
}

.step-count {
    border-radius: 50%;
    font-size: 16px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--dark);
    color: #ffffff;
    margin-right: 10px;
}

.step-name {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #415464;
}


.disableSteps .step-count{
    border: 2px solid #D9D9D9 !important;    
    color: #979797 !important;
    background: none !important;
}

.disableSteps .step-name{
    color: #979797 !important;
}

.edit-expe-label{
    font-weight: 400;
    font-size: 28px;
    line-height: 145%;
    margin-top: 20px;
}

.edit-image-exp-container {
    display: flex;
    /* cursor: pointer; */
    margin-top: 20px;
    justify-content: space-between;
    align-items: flex-start;
}

.edit-experience-container {
    max-width: 500px;
    min-width: 500px;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 25px;
}


.upload-version-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
 }
 
 .upload-model {
     display: flex;
     flex-direction: column;
     align-items: center;
 }
 
 .version-group {
     height: 80px;
     align-items: center;
 }
 
 .version-item {
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     padding: 20px;
     border-bottom: 1px solid #D8D8D8;
 }
 
 .active-status {
     font-weight: 700;
     font-size: 16px;
     line-height: 22px;
     padding-left: 20px;
     padding-right: 20px;
     color: #697C8C;
 }
 
 .version-name {
   font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #343338;
    word-break: break-all;
 }

.version-radio-btn {
    transform:scale(1.3);
    color: var(--dark);
}

.version-radio-btn .form-check-input:checked {
    border-color:  var(--dark);
    background-color:  var(--dark);
}

.version-radio-btn .form-check-input {
    border-color: var(--dark);
    background-color: #E6E8EB;
    cursor: pointer;
}

.version-radio-btn .form-check-input:hover {
    border-color: var(--btnHover);
    background-color:var(--btnHover);
}

.form-check-input:focus {
    box-shadow: none;
}
.version-title-div {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.version-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--dark);
}

.version-subTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #73808B;
}

.content-div {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 0;
    margin-bottom: 0;
}
.versionList {
    margin-top: 32px;
    padding: 0;
}

.activeVersion {
    background: #DCE0E3;
}

.warning-bg{
    background: #F7F7F7;
}

.text-header {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px; 
}

.div-flex-model {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.delete-subText {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 145%;
    color: #3B3B3B;
    margin-bottom: 30px;
}
.delete-subText-warning{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 145%;
    color: #3B3B3B;
}
.delete-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.warning-delete {
    padding: 20px;
    border: 2px solid #BE2222;
    border-radius: 10px;
    width: max-content;
    max-width: 680px;
}
.warning-icon {
    color: #BE2222;
    height: 18px;
    width: 18px;
    margin-right: 4px;
}

.warning-div {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.editText {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;  
    color: #415464;
    margin: 15px 0 30px 0;
}

.warningText {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;  
    color: #3B3B3B;
}

.delete-icon {
    height: 18px;
    width: 18px;
}
 
.input-div {
    width: 400px;
    margin-top: 10px;
}

.thumbnail-img {
    /*height: 140px;*/
     width: 150px; 
}

.delete-logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;
    margin-bottom: 50px;
}

.delete-org-heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #415464;
}
.module-headers {
  margin-bottom: 10px;
}

.edit-module-icon {
    height: 24px;
    width: 24px;
    margin: 0px 10px 0px 25px;
    cursor: pointer;
    z-index: 100;
    color:var(--dark);
}

.edit-module-icon:hover{   
    color:var(--btnHover);
}

.module-name {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-module-name {
    color: #343338;
    padding: 10px 40px 10px 10px;
    /* margin-left: 12px; */
    border: 1px solid #D9D9D9;
    background-color: transparent;
    border-radius: 2px;
    width: 240px;
}

.module-name-box input:focus {
    outline: none;
}

.edit-close {
    height: 24px;
    width: 24px;
    cursor: pointer;
    z-index: 100;
    margin: 10px;
    position: absolute;
    color:var(--secondary);
}

.module-name-box {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: relative;
    /* margin-right: 60px; */
    float: right;
}

.courseName {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
    word-break: break-word;
    color:#3B3B3B;
}
.module-box {
    display: inline-flex;
    flex-direction: column;
    margin-top: 8px;
    height: 40px;
    align-items: flex-start;
}

.confirm-title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #3B3B3B;
}

.text-wrap {
    flex-wrap: wrap;
}

.confirmation-dialog {
    max-width: 640px;
}

.width-100{
    width: 100%;
}

.module-level{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #415464;
   
}

.module-dropDown{
    width: 500px;
}

.module-dropDown .css-1jqq78o-placeholder{
    margin-left: 14px !important;
}

.module-dropDown .css-1dimb5e-singleValue{
    margin-left: 14px !important;
}