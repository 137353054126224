.bg-success,
.bg-danger,
.bg-dark,
.bg-info {
    background-color: #FFF !important;
}

.bg-success{
    border:2px solid #28A54B !important;
}

.bg-danger{
    border: 2px solid #BE2222 !important;
}
.toast-container-wrapper {
    position: fixed;
    right: 0;
    top: 10px;
    width: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
}

.toast-body {
    padding: var(--bs-toast-padding-x);
    word-wrap: break-word;
    display: flex;
    height: 60px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 145%;
    color: #415464;
    align-items: center;
    justify-content: center;
}

.toast-message {
    animation: fadeInRight 0.3s ease-in-out;
    animation-fill-mode: forwards;
    box-shadow: 0px 11px 42px -4px #c8c8c8;
    border-radius: 10px;
    border: none;
}

.toast-text {
    white-space: pre-wrap;
  }

.toast-message:hover {
    box-shadow: 0px 11px 42px -4px #c8c8c8;
    opacity: 1;
    transition: 0.3s ease;
}

@keyframes fadeInRight {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}