

.lti-container{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.lti-input-box{
    width: 600px;
}