@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css');
@import url('react-calendar/dist/Calendar.css');

:root {
  --primary: #415464;
  --dark: #415464;
  --lightDark: #607180;
  --white: #ffffff;
  --light: #f1f1f1;
  --purple: #9747FF;
  --text: #3B3B3B;
  --whiteBackground: #FAFAFA;
  --secondary: #415464;
  --btnHover: #768390;
}

body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
}

.muted-text {
  color: #C0C0C0;
}

.brand-logo {
  content: url('./images/sr-logo.svg');
  width: 364px;
  /* height: 40px; */
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #EBEBEB;
}

::-webkit-scrollbar-thumb {
  background: #607180;
}

::-webkit-scrollbar-thumb:hover {
  background: #415464;
}

.header-color {
  color: var(--text)
}

.subText-color {
  color: #3B3B3B;
}

.white-text {
  color: var(--white)
}

.primary-color {
  color: var(--dark) !important;
}

.secondary-color {
  color: var(--secondary)
}

.content-bgrd {
  background-color: var(--dark);
}

.content-bgrd:hover:active:focus {
  background-color: var(--btnHover);
}

.background-color {
  background: var(--whiteBackground);
}

.border-color {
  border-color: var(--dark);
}

.btn-primary:hover {
  background-color: var(--btnHover);
  border-color: var(--btnHover);
}

.cancelbtn:hover {
  color: var(--btnHover) !important;
}

.height-100 {
  height: 100vh;
}

.react-daterange-picker, .react-daterange-picker__wrapper {
  margin-left: 4px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  height: 36px;
  padding-left: 5px;
}

.react-daterange-picker__wrapper {
  background: #fff;
}

.react-daterange-picker__wrapper input{
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  color: hsl(0, 0%, 20%);
}

.react-daterange-picker__wrapper  svg{
  stroke: hsl(0, 0%, 80%);
}