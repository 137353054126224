

.profile-container{   
    display: inline-flex;
    align-items: center;
}

.org-profile-header{
    font-family: 'Mulish';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 145%;
color: #3B3B3B;
/* or 20px */
}

.org-profile-header-width{
    width: 200px;
    margin-right: 40px;
}
.profile-container .form-group{
    margin-bottom: 0;
}

.profile-container .thumbnail-img{
    margin-right: 50px;
    height: auto;
}

.profile-container .colorTypeText{
    width: 200px;
    margin-right: 40px;
}


.lti-profile-container{
    display: flex;
    flex-direction: column;
}

.select-exp-dropDown {
 margin-right: 15px !important;
 margin-left: 8.2rem !important;
 width: 250px;
}