.content-wrapper {
    display: flex;
    height: calc(100vh - 64px);
}

.content {
    overflow: auto;
    width: 100%;
    padding: 66px 77px;
    background-color: #FAFAFA;
}

.content-margin {
    padding: 0px 104px;
}

.dashboard-content {
    margin-bottom: 72px;
}

.hidden {
    display: none !important;
}

.vh-100 {
    height: 100vh !important;
}

.client-logo {
    min-height: 50px;
}

.back-button {
    width: max-content;
    height: 18px;
    display: flex;
}

.back-url {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #FFFFFF;
    cursor: pointer;
}

.back-url:hover {
    text-decoration: underline;
}

.back-breadcrumb-container {
    display: flex !important;
    align-items: center;
    flex-direction: row;
}

.left-sidebar {
    min-width: 220px;
    box-shadow: 0px 19px 29px rgb(0 0 0 / 17%);
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.left-sidebar-content {
    flex: 1;
    padding: 30px 20px 20px 20px;
    max-width: 300px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dark);
    line-height: 50px;
    padding: 10px 20px;
    position: relative;
    z-index: 100;
}

.user-profile {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}

.user-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-name {
    font-size: 14px;
    font-weight: 700;
    line-height: 17.57px;
    color: var(--white);
    cursor: pointer;
    text-transform: capitalize;
}

.user-image {
    width: 40px;
    height: 40px;
    background: #F1F1F1;
    border-radius: 3.98374px;
    color: var(--dark);
    font-weight: 700;
    letter-spacing: 0.15rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3.98374px;
}

.add-item-container {
    width: 326px;
}

.add-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
    border: 2px dashed var(--dark);
    border-radius: 10px;
    width: 100px;
    height: 100px;
    cursor: pointer;
    color: var(--dark);
    font-size: 24px;
}

.add-item:hover {
    border: 2px dashed var(--btnHover);
    color: var(--btnHover);
    background: #F5F5F5;
}

.add-experience {
    width: 401px;
    height: 120px;
}

.add-client {
    min-width: 180px;
    width: 380px;
    height: 86px;
}

.invite-owner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-margin {
    padding: 66px 0px;
}

.modal-fullscreen .modal-body {
    overflow-y: unset;
}

.cancelbtn {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-decoration-line: underline;
    color: var(--dark);
    min-width: 100px;
}

/* 
.model-dialog .modal-fullscreen {
    min-height: 100vh;
    overflow-y: auto;
} */

/* .modal-open {
    overflow-y: auto !important;
} */
.modal-fullscreen .modal-content {
    min-height: 90vh;
    max-height: -moz-fit-content;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    position: absolute;
    bottom: 0;
}

.invite-owner p {
    font-size: 14px;

    color: #415464;
}

.experience-user-details {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.module-details {
    margin-top: 30px;
    width: 100%;
}

.module-mode-select {
    margin-top: 20px;
    margin-bottom: 30px;
    /* width: 100%; */
    display: flex;
    flex-direction: inherit;
    justify-content: center;
}

.button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 25px;
}

.error {
    /* color: #BE2222; */
}

.baseMagin {
    margin: 20px
}

.text-normal {
    text-transform: inherit;
}

.h-100vh {
    height: 100vh;
}


.u-line {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.member-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.member-item {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 326px;
    height: 100px;
    border-radius: 10px;
    gap: 16px;
    position: relative;
}

.member-image img {
    width: 100px;
    height: 100px;
    background: #F1F1F1;
    border-radius: 10px;
    color: #FFF;
    font-weight: 700;
    letter-spacing: 0.15rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.member-image {
    position: relative;
}

.remove-user-icon {
    color: var(--dark);
    background-color: var(--white);
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
}

.remove-user-icon:hover {
    color: var(--btnHover)
}

.member-item:hover .member-edit {
    display: flex;
}

.member-delete {
    width: unset;
    height: fit-content;
    position: absolute;
    top: -7px;
    left: -7px;
}

.member-edit {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(65, 84, 100, 0.4);
    border-radius: 10px;
    color: #FFF;
    font-size: 24px;
}

.member-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 200px;
}

.member-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #3B3B3B;
}

.member-role {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8E94AD;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
}


.client-item {
    display: flex;
    align-items: center;
    /* width: fit-content; */
    width: 380px;
    height: 86px;
    border-radius: 10px;
    cursor: pointer;
    gap: 16px;
    position: relative;
    background: #F1F1F1;
}


.client-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px 0px 0px 10px;
    height: 100%;
    min-width: 180px;
    max-width: 180px;
}

.client-image {
    position: relative;
}

.client-details {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
}

.client-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #3B3B3B;
    width: 158px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.client-item:hover .client-edit {
    display: flex;
}

.client-edit {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(65, 84, 100, 0.4);
    border-radius: 10px;
    color: var(--white);
    font-size: 24px;
}

.client-item img {
    width: 100%;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
}

/* ///////////////////////////// */


.experience-item {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 380px;
    height: 120px;
    border-radius: 10px;
    cursor: pointer;
    gap: 16px;
    position: relative;
    background: #F1F1F1;
}


.experience-image img {
    width: 145px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px 0px 0px 10px;
}

.experience-image {
    position: relative;
}

.experience-details {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 240px;
    padding-right: 14px;
}

.experience-name {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #3B3B3B;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.experience-item:hover .experience-edit {
    display: flex;
}

.experience-edit {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: rgba(65, 84, 100, 0.4);
    border-radius: 10px;
    color: var(--white);
    font-size: 24px;
}

.menu-list {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}

a.menu-item {
    text-decoration: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 60px;
    border-radius: 4px;
    position: relative;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #415464;
    border: 1px solid #F1F1F1;
    margin-bottom: 20px;
    position: relative;
}

.menu-item.active {
    background: #F1F1F1;
    border: 1px solid #F1F1F1;
    color: var(--dark);
}

.menu-item.active i {
    color: var(--secondary);
}

.menu-item-icon {
    display: none;
}

.menu-item.active .menu-item-icon {
    display: block;
}


.menu-item:hover {
    border-color: #CFCFCF;
    color: var(--dark)
}

.profile-dropdown {
    z-index: 1;
    min-width: 279px;
    height: fit-content;
    position: absolute;
    /* left: 78vw; */
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #FFFFFF;
    box-shadow: 4px 0px 74px -40px #9fc2cc;
}

.header-user-profile-btn {
    height: 50px;
    padding-left: 33px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-user-profile-btn:hover {
    background: rgba(65, 84, 100, 0.1);
}

.header-user-email {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */
    padding: 15px;
    text-align: center;
}

.header-profile-img img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.header-profile-img {
    display: flex;
    padding-top: 20px;
    justify-content: center;
    align-items: center;
}

.profile-popup-close {
    position: absolute;
    width: 100vw;
    height: 90vh;
}

.profile-thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.user-profile-picture img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.user-profile-picture {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    width: 550px;
}

.user-delete img {
    width: 24px;
    height: 17px;
}

.user-delete span {
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #415464;
    width: max-content;
}

.user-delete span:hover {
    text-decoration: underline;
}

.user-profile-container {
    position: relative;
}

.user-profile-container:hover .member-edit {
    display: flex;
}

.user-edit-profile-email {
    font-weight: 500;
    width: -webkit-fill-available;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #3B3B3B;
    word-break: break-word;
    font-family: 'Mulish';
}

.user-edit-profile-name {
    font-weight: 700;
    width: -webkit-fill-available;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #3B3B3B;
    word-break: break-word;
    font-family: 'Mulish';
}

.thumbnail-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thumbnail-text {
    position: absolute;
    bottom: 15px;
    left: 35px;
    font-weight: 700;
}

.thumbnail-gradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 54px;
    background: linear-gradient(180deg, rgba(200, 199, 199, 0) 0%, #A9A9A9 89.81%);
    mix-blend-mode: multiply;
}

.course-description {
    max-height: 300px;
    overflow-y: auto;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    color: #3B3B3B;
}

.back-btn {
    display: flex;
    text-align: start !important;
    text-decoration: underline;
    align-items: center;
}

.back-btn:active {
    border: none;
}

.back-arrow {
    width: 25px;
    height: 25px;
}

.course-back-btn {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: #A8A8A8;
    margin: 6px;
    cursor: pointer;
}

.course-back-btn-div {
    padding-bottom: 25px;
}

.delete-modal-header-2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 145%;
}

.delete-modal-body {
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
}

.delete-modal-picture {
    display: flex;
    padding-top: 20px;
    justify-content: left;
    align-items: center;
    margin-bottom: 15px;
}

.delete-modal-picture img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.delete-heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 145%;
    text-align: center;
}

.user-delete {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.w-fit-content {
    width: fit-content !important;
}

.module-title {
    text-transform: capitalize;
    /* display: flex; */
    margin-bottom: 10px;
    padding-right: 60px;
}

.lightGrey {
    color: #79838B;
}

.active-Link-div {
    background: var(--light);
    width: max-content;
    border-radius: 10px;
    padding: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    word-break: break-all;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis;*/
}

.copy-icon-div {
    background: var(--dark);
    border-radius: 10px;
    padding: 18px;
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.copy-icon-div:hover {
    background: var(--btnHover);
}

.module-active-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.module-icon {
    color: var(--dark);
    height: 32px;
    width: 40px;
}

.version-download-icon {
    color: var(--dark);
    height: 32px;
    width: 40px;
}

.version-download-icon:hover {
    color: var(--btnHover);
}

.download-icon {
    color: #FFFFFF;
    height: 24px;
    width: 24px;
}

.copy-link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #7F85A2;
    white-space: nowrap;
}

.add-partner-sub-body {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    text-align: center;
    color: #415464;
    margin-bottom: 50px;
    width: 550px;
}

.multiSelectContainer {
    width: 550px !important;
}

.searchBox {
    background: transparent;
    border: none;
    margin-top: 3px;
    width: 300px;
}

.chip,
.singleChip {
    white-space: break-spaces !important;
}


.searchWrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 22px;
    padding: 12px !important;
    position: relative;
}

.delete-modal-course-list {
    display: list-item;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    margin-left: 17px;
}

.transform-180 {
    transform: rotate(180deg);
}

.profile-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
}

.profile-left {
    width: 100%;
}

.profile-input-heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
}

.profile-btn-link {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #415464;
    margin-bottom: 10px;
    cursor: pointer;
}


.profile-btn-link:hover {
    text-decoration-line: underline;
}

.profile-info {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    text-align: right;
    color: #A8A8A8;
}

.delete-image-title {

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: start;
    margin-bottom: 20px;
    color: #3B3B3B;

}

.delete-image-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.experience-mode {
    position: absolute;
    width: 40px;
    bottom: 10px;
    right: 10px;
}

.module-mode {
    width: 40px;
    margin-bottom: 30px;
}

.analytic-mode {
    width: 40px;
    margin-right: 20px;
}