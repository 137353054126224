.form-group {
    margin-bottom: 20px;
    position: relative;
}

.form-control {
    border-radius: 2px;
    border: 1px solid #D9D9D9;
    padding: 19px 20px;
    font-Weight: 400;
    font-size: 18px;
    line-height: 22.59px;

}

/* .form-control.error, */
input.error {
    border: 1px solid #BE2222;
}

textarea.error {
    border: 1px solid #BE2222;
}

.form-control:focus {
    box-shadow: none;
}


.password-icon {
    position: absolute;
    right: 20px;
    top: 23px;
    margin: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.btn {
    border-radius: 2px;
    padding: 18px 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    transition: all 0.3s ease 0s;
    outline: none;
    min-width: 170px;
}

.error-message {
    color: #BE2222;
    font-size: 14px;
    line-height: 17.57px;
    font-weight: 500;
    margin-top: 5px;
    text-transform: none;
}

.upload-heading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 145%;
    color:#3B3B3B;
    /* or 41px */

    text-align: center;
}

.upload-subheading {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */
    text-align: center;
    /* color: #415464; */
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--dark);
    --bs-btn-border-color: var(--dark);
    --bs-btn-hover-color: #fff;
     --bs-btn-hover-bg: var(--dark);
    --bs-btn-hover-border-color: var(--dark);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
     --bs-btn-active-bg: var(--dark);
    --bs-btn-active-border-color: #283760718043;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #B5B5B5;
    --bs-btn-disabled-bg: #DFDFDF;
    --bs-btn-disabled-border-color: #DFDFDF;
    text-transform: capitalize !important;  

}

.btn-outline-primary {
    --bs-btn-color: var(--dark);
    --bs-btn-border-color: var(--dark);
    --bs-btn-hover-color: var(--dark) 
    --bs-btn-hover-bg: transparent;
     --bs-btn-hover-border-color: var(--dark); 
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #415464;
     --bs-btn-active-bg: var(--dark);
    --bs-btn-active-border-color: var(--dark); 
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #B5B5B5;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #B5B5B5;
    --bs-gradient: none;
}

.btn-link-secondary{
  color : var(--dark)
}

.btn-link-secondary:hover{
  color : var(--dark)
}

.btn-link-secondary:hover .back-arrow{
  color : var(--btnHover)
}

.btn-link-secondary:hover .cancelbtn{
  color : var(--btnHover)
}
.linear-progressbar-complete {
    height: 4px;
    width: 500px;
}

.linear-progressbar-loading {
  height: 4px;
  width: 500px;
}

.progressbar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.linear-progressbar-complete .progress-bar {
    background-color: var(--dark);
  }

.linear-progressbar-loading .progress-bar {
    background-color: var(--secondary);
  }
.icons {
    margin: 8px;
}

.dropdown-background{
    background: var(--dark);    
    border-radius: 4px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color:  #FFFFFF;
}

.dropdown-background:hover{
    background: var(--btnHover) !important;    
}
/* .show div:first-child {
    background: #415464 !important;
    color:  #F1F1F1 !important;
} */

.dropdown-menu{
    background: var(--dark);
    border-radius: 2px;
    min-width: auto;
    padding: 0;
}

.dropDown-menu-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background: var(--dark);
  opacity: 0.8;
  backdrop-filter: blur(2px);
  color: #FFFFFF;
  display: flex;
}

.dropdown-item{
    color: #FFFFFF;
    border-bottom: var(--btnHover);
}

.confirm-model {
    margin: 42px 66px;
}

.subText-model {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #415464;
}

.content-model {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.button-model {
   line-height: 1px;
   min-width: 100px;
}

.list-style {
   margin: 0;
   padding: 0px;
}
 
.list-style a:not(:last-child){
    border-bottom: 1px solid #FFFFFF;
 }

  ul.options {
    display: block;
    list-style: none;
    width: 100%;
    transition: width 0.3s;
    margin: auto;
    position: absolute;
    z-index: 999999999;
    background: #fff;
    padding: 0;
    border: 1px solid #ddd
  }
  
  ul.options li {
    display: block;
    background: white;
    margin: 10px auto;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border-radius: 2px;
  }
  ul.options li:hover {
    font-weight: bold;
    color: #8F9EAA;
    cursor: pointer;
    transition: 0.1s all;
    background: whitesmoke;
  }
  
  ul.options li.option-active {
    background: whitesmoke;
    font-size: 1rem;
    color: #8F9EAA;
  }
  .no-options {
    color: white;
  }
 
  .autocomplete-parent{
    width: 100%;
    position: relative;
  }

  .cursor-pointer {
    cursor: pointer;
  }
  
  .loading-text-size {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color:#A8A8A8
  }

  .chevron-icon{
    position: absolute;
    top: 23px;
    right: 20px;
    color: #415464;
  }


  .passwordWidth{
    min-width: 500px
  }

 .crop-container {
    position: relative;
    height: 360px;
    width: auto;
    /* margin: auto;*/
}

.fade{
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1075
}

.crop-model{
  margin: 30px;
  display: flow-root;
  justify-content: center;
  align-items: center;
  text-align: center;  
}

.crop-title{
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */  
  text-align: center;
  margin-bottom: 30px;  
  color: #3B3B3B;
}

input[type="range"] {
  --thumbSize: 18px;
  --trackSize: 8px;
  --thumbBg: var(--dark);
  --trackBg: rgba(241, 241, 241, 1);
  --progressBg: var(--dark);

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}
input[type="range"]:hover{
--thumbBg: var(--secondary) !important;
}
input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: var(--thumbSize);
  width: 100%;
  margin: 0;
  padding: 0;
}
input[type="range"]:focus {
  outline: none;
}

/* Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type="range"]::-ms-thumb {
  -ms-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* Track */
input[type="range"]::-webkit-slider-runnable-track {
  height: var(--trackSize);
  background-image: linear-gradient(
    90deg,
    var(--progressBg) var(--webkitProgressPercent),
    var(--trackBg) var(--webkitProgressPercent)
  );
  border-radius: calc(var(--trackSize) / 2);
}
input[type="range"]::-moz-range-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}
input[type="range"]::-ms-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
  border-radius: calc(var(--trackSize) / 2);
}

/* Progress */
input[type="range"]::-moz-range-progress {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type="range"]::-ms-fill-lower {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}


.zoom-corp{
  padding: 20px 0px 40px 0px; 
  display: flex;
}

.reactEasyCrop_Container{
  border-radius: 10px;
}

.icon-dropdown{
  color: #FFFFFF;
  height: auto;
  width:  14px;
  margin-right: 5px;
}

.dropdown-item:hover .icon-dropdown{
  color: var(--bs-dropdown-link-hover-color);;
}

.calendar-export{
  width: 250px !important;
  margin-left: 8rem;
  height: 20px;
  font-size: 16px;
}

.calenderIcon{
  position: absolute;
  right: 10px;
}

.export-warning{
  font-size: 14px;
  margin-left:215px ;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #415464 !important;
    border-color: transparent !important;
    color: #fff;
    border-radius: 50% !important;
}

.cancelBtn {
    margin-left: 8px ;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 8px !important;
    font-size: 16px !important;
}
.applyBtn {
  font-size: 14px !important;
}
.daterangepicker{
      font-family: 'Mulish' !important;
}

.daterangepicker .calendar-table th{
  font-size: 16px !important;
}

.daterangepicker .calendar-table td{
  font-size: 14px !important;
}

.drp-selected{
    font-size: 14px !important;
}

.daterangepicker .drp-calendar.right {
    padding: 15px !important;
    padding-left: 20px !important;
}

.daterangepicker .drp-calendar.left{
      padding: 15px !important;
      padding-left: 30px !important;
}